import * as CryptoJS from 'crypto-js';

export const encryptData = <T extends Record<string, unknown>>(data: T, encryptSecretKey: string) =>
  CryptoJS.AES.encrypt(JSON.stringify(data), encryptSecretKey).toString();

export const decryptData = <T extends Record<string, unknown>>(data: T, encryptSecretKey: string) => {
  const bytes = CryptoJS.AES.decrypt(data, encryptSecretKey);
  if (bytes.toString()) {
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  }
  return data;
};
