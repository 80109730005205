import { NgModule } from "@angular/core";

import { FeatherIconDirective } from "@core/directives/core-feather-icons/core-feather-icons";
import { RippleEffectDirective } from "@core/directives/core-ripple-effect/core-ripple-effect.directive";
import { FileDropDirective } from "./file-drop.directive";
import { FileDirective } from "./file.directive";
import { NgxTableResizerDirective } from "./ngx-table-resizer.directive";
import { TextAreaDirective } from "./text-area.directive";

@NgModule({
  declarations: [
    RippleEffectDirective,
    FeatherIconDirective,
    TextAreaDirective,
    FileDirective,
    FileDropDirective,
    NgxTableResizerDirective,
  ],
  exports: [
    RippleEffectDirective,
    FeatherIconDirective,
    TextAreaDirective,
    FileDirective,
    FileDropDirective,
    NgxTableResizerDirective,
  ],
})
export class CoreDirectivesModule {}
