import { Gender } from './common';
import { SocialEntity, SocialProvider } from './social';

export enum INDUSTRY_GROUPS {
  PERSONAL_TRAINER = 'Personal Trainer',
  MEAL_PREP = 'Meal Prep',
  INFLUENCER = 'Influencer',
  DOCTOR = 'Doctor',
  ADVISOR = 'Advisor',
  MARKETING = 'Marketing',
  PRODUCT = 'Product',
}

export enum Roles {
  ADMIN = 'admin',
  USER = 'user',
}

export interface User {
  id?: string;
  username?: string;
  firstName?: string;
  lastName?: string;
  middleName?: string;
  mobile?: string;
  email: string;
  businessName?: string;
  title?: string;
  dateOfBirth?: string;
  address?: string;
  zipCode?: string;
  city?: string;
  state?: string;
  gender?: Gender;
  country?: string;
  lastLogin?: Date;
  isActive?: boolean;
  provider?: SocialProvider;
  roles?: Roles[];
  createdAt?: Date;
  updatedAt?: Date;
  avatar?: string;
  reason?: string;
  status?: string;

  businessSize?: string;
  businessType?: string;
  files?: Array<{ createdAt: Date; file: string; note: string }>;
  hisotry?: Array<{ createdAt: Date; title: string }>;
  industry?: string;
  lastMonthRevenue?: number;
  notes?: Array<{ createdAt: Date; note: string }>;
  numberOfFollowers?: number;
  partnerCommission?: number;
  referralCode?: string;
  socials?: SocialEntity[];
  totalRevenue?: number;
  onHoldAmount?: number;
}
