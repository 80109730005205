import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { saveAs } from 'file-saver';

import { BankDetails, CreditCard, Roles, StorageList, User } from '@types';
import { Payout } from '@/app/@types/payout';
import { AuthenticationService } from '@/app/auth/service';

@Injectable()
export class ProfileService {
  public user: User;
  public users: User[] = [];
  public onUserChanged: BehaviorSubject<User>;
  public onUsersChanged: BehaviorSubject<User[]>;

  constructor(private _httpClient: HttpClient, private _authenticationService: AuthenticationService) {
    this.onUserChanged = new BehaviorSubject(null);
    this.onUsersChanged = new BehaviorSubject([]);
  }

  getProfile(id: string): Promise<User> {
    return new Promise((resolve, reject) => {
      this._httpClient.get<User>(`/api/user/${id}`).subscribe((response: any) => {
        this.user = response;
        this.onUserChanged.next(response);
        resolve(response);
      }, reject);
    });
  }

  updateProfile(profile: Partial<User>): Promise<User> {
    return new Promise((resolve, reject) => {
      this._httpClient.put<User>(`/api/user/${profile.id}`, profile).subscribe((response: any) => {
        this.user = response;
        this.onUserChanged.next(response);
        this._authenticationService.currentUser.next(response);
        resolve(response);
      }, reject);
    });
  }

  createProfile(profile: Partial<User>): Promise<User> {
    return new Promise((resolve, reject) => {
      this._httpClient.post<User>(`/api/user`, profile).subscribe((response: any) => {
        this.user = response;
        this.onUserChanged.next(response);
        resolve(response);
      }, reject);
    });
  }

  addRoles(id: string, role: Roles): Promise<User> {
    return new Promise((resolve, reject) => {
      this._httpClient.put<User>(`/api/user/role/${id}/${role}`, null).subscribe((response: any) => {
        this.user = response;
        this.onUserChanged.next(response);
        resolve(response);
      }, reject);
    });
  }

  approveProfile(id: string): Promise<User> {
    return new Promise((resolve, reject) => {
      this._httpClient.put<User>(`/api/user/approve/${id}`, null).subscribe((response: any) => {
        this.user = response;
        this.onUserChanged.next(response);
        resolve(response);
      }, reject);
    });
  }

  unapproveProfile(id: string): Promise<User> {
    return new Promise((resolve, reject) => {
      this._httpClient.put<User>(`/api/user/unapprove/${id}`, null).subscribe((response: any) => {
        this.user = response;
        this.onUserChanged.next(response);
        resolve(response);
      }, reject);
    });
  }

  getActiveProfiles(): Promise<User[]> {
    return new Promise((resolve, reject) => {
      this._httpClient.get<User[]>(`/api/user/active`).subscribe((response: User[]) => {
        this.users = response;
        this.onUsersChanged.next(response);
        resolve(response);
      }, reject);
    });
  }

  getInReviewProfiles(): Promise<User[]> {
    return new Promise((resolve, reject) => {
      this._httpClient.get<User[]>(`/api/user/review`).subscribe((response: User[]) => {
        this.users = response;
        this.onUsersChanged.next(response);
        resolve(response);
      }, reject);
    });
  }

  deleteProfile(profileId: string): Promise<HttpResponse<void>> {
    return new Promise((resolve, reject) => {
      this._httpClient.delete<void>(`/api/user/${profileId}`, { observe: 'response' }).subscribe(resolve, reject);
    });
  }

  listAllFiles(id: string): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this._httpClient.get<StorageList>(`/api/storage/files/${id}`).subscribe((response: any) => {
        this.onUserChanged.next(response);
        resolve(response);
      }, reject);
    });
  }

  uploadFile(id: string, file: File, fileNote: string): Promise<any[]> {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('note', fileNote);
    return new Promise((resolve, reject) => {
      this._httpClient.post<StorageList>(`/api/storage/files/${id}`, formData).subscribe((response: any) => {
        this.getProfile(id).then();
        resolve(response);
      }, reject);
    });
  }

  downloadFile(applicantId: string, fileName: string): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .get(`/api/storage/${fileName}`, { responseType: 'blob', observe: 'response' })
        .subscribe((response: any) => {
          saveAs(response.body, fileName.split(applicantId + '/')[1]);
          resolve(response.body);
        }, reject);
    });
  }

  deleteFile(id: string, fileName: string): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this._httpClient.delete(`/api/storage/${fileName}`).subscribe((response: any) => {
        this.getProfile(id).then();
        resolve(response);
      }, reject);
    });
  }

  invitePartner(partner: {firstName: String; lastName: String; email: String}): Promise<User> {
    return new Promise((resolve, reject) => {
      this._httpClient.post<User>(`/api/user/invite`, partner).subscribe((response: User) => {
        resolve(response);
      }, reject);
    });
  }

  // TODO change any to the entity 
  withdrawMoney(amount: number, isBankTransfer: boolean): Promise<HttpResponse<Payout>> {
    return new Promise((resolve, reject) => {
      this._httpClient.post<Payout>(`/api/payment/withdraw`, {amount, isBankTransfer}, { observe: 'response' }).subscribe(resolve, reject);
    });
  }

  changePassword(oldPassword: string, newPassword: string): Promise<User> {
    return new Promise((resolve, reject) => {
      this._httpClient.post<User>(`/api/password/change`, { oldPassword, newPassword }).subscribe((response: User) => {
        resolve(response);
      }, reject);
    });
  }

  saveCreditCard(token: string): Promise<CreditCard> {
    return new Promise((resolve, reject) => {
      this._httpClient.post<CreditCard>(`/api/payment/credit-card`, { token }).subscribe((response: CreditCard) => {
        resolve(response);
      }, reject);
    });
  }

  getCreditCard(id: string): Promise<CreditCard> {
    return new Promise((resolve, reject) => {
      this._httpClient.get<CreditCard>(`/api/payment/credit-card/${id}`).subscribe((response: CreditCard) => {
        resolve(response);
      }, reject);
    });
  }

  deleteCreditCard(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this._httpClient.delete<boolean>(`/api/payment/credit-card`).subscribe((response: boolean) => {
        resolve(response);
      }, reject);
    });
  }

  saveBankDetails(token: string): Promise<BankDetails> {
    return new Promise((resolve, reject) => {
      this._httpClient.post<BankDetails>(`/api/payment/bank-details`, { token }).subscribe((response: BankDetails) => {
        resolve(response);
      }, reject);
    });
  }

  getBankDetails(id: string): Promise<BankDetails> {
    return new Promise((resolve, reject) => {
      this._httpClient.get<BankDetails>(`/api/payment/bank-details/${id}`).subscribe((response: BankDetails) => {
        resolve(response);
      }, reject);
    });
  }

  deleteBankDetails(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this._httpClient.delete<boolean>(`/api/payment/bank-details`).subscribe((response: boolean) => {
        resolve(response);
      }, reject);
    });
  }

  getTop5ReferralPartners(): Promise<User[]> {
    return new Promise((resolve, reject) => {
      this._httpClient.get<User[]>(`/api/user/partner/top-5`).subscribe((response: User[]) => {
        resolve(response);
      }, reject);
    });
  }

  addReferrals(users: User[]): Promise<User[]> {
    return new Promise((resolve, reject) => {
      this._httpClient.post<User[]>(`/api/user/referrals`, {users}).subscribe((response: User[]) => resolve(response), reject);
    });
  }
}
