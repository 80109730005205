import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import Swal from 'sweetalert2';

import { AuthenticationService } from 'app/auth/service';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
  constructor(private _authenticationService: AuthenticationService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const user = this._authenticationService.currentUserValue;
    const isUpdating = request.method === 'POST' || request.method === 'PUT';
    const isNotAuthUrl = !(
      request.url.includes('user') ||
      request.url.includes('password') ||
      request.url.includes('auth')
    );
    if (isUpdating && !user?.isActive && isNotAuthUrl) {
      Swal.fire({
        icon: 'error',
        title: 'Pending approval!',
        text: 'Your profile is under review, you can only view the Partner Portal.',
        customClass: {
          confirmButton: 'btn btn-success',
        },
      });
      return of(null);
    }

    return next.handle(request);
  }
}
