import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ROUTES } from '@consts';
import { isTokenExpired } from '@utils';

import { AuthenticationService } from 'app/auth/service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  /**
   *
   * @param {Router} _router
   * @param {AuthenticationService} _authenticationService
   */
  constructor(private _router: Router, private _authenticationService: AuthenticationService) {}

  // canActivate
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this._authenticationService.currentUserValue;
    const isCurrentTokenExpired = isTokenExpired(this._authenticationService.accessToken);
    if (currentUser && !isCurrentTokenExpired) {
      // check if route is restricted by role
      if (route.data.roles && route.data.roles.some((role) => currentUser.roles.includes(role)) === -1) {
        // role not authorised so redirect to not-authorized page
        this._router.navigate([ROUTES.NOT_FOUND]);
        return false;
      }

      // In case the user didn't finish his registration
      if (!state.url.includes(ROUTES.PAGE_PRE_PROFILE) && (!currentUser.firstName || !currentUser.lastName)) {
        this._router.navigate([`${ROUTES.PAGE_PRE_PROFILE}${currentUser.id}`], { queryParams: { returnUrl: state.url } });
        return false;
      }

      // authorised so return true
      return true;
    }

    // not logged in so redirect to login page with the return url
    this._router.navigate([ROUTES.LOGIN], { queryParams: { returnUrl: state.url } });
    return false;
  }
}
