import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'environments/environment';
import { User } from '@types';

@Injectable({ providedIn: 'root' })
export class UserService {
  /**
   *
   * @param {HttpClient} _http
   */
  constructor(private _http: HttpClient) {}
  /**
   * Get user by id
   */
  getById(id: string) {
    return this._http.get<User>(`${environment.apiUrl}/user/${id}`);
  }
}
