import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { PartnerCode } from '@types';

@Injectable()
export class PartnerCodeService {
  public partnerCode: PartnerCode;
  public partnerCodes: PartnerCode[];
  public onPartnerCodeChanged: BehaviorSubject<PartnerCode>;
  public onPartnerCodesChanged: BehaviorSubject<PartnerCode[]>;

  constructor(private _httpClient: HttpClient) {
    this.onPartnerCodeChanged = new BehaviorSubject(null);
    this.onPartnerCodesChanged = new BehaviorSubject([]);
  }

  getPartnerCodes(): Promise<PartnerCode[]> {
    return new Promise((resolve, reject) => {
      this._httpClient.get<PartnerCode[]>(`/api/partner-code/`).subscribe((response: PartnerCode[]) => {
        this.partnerCodes = response;
        this.onPartnerCodesChanged.next(response);
        resolve(response);
      }, reject);
    });
  }

  checkIfCodeIsValid(partnerCode: string): Promise<PartnerCode> {
    return new Promise((resolve, reject) => {
      this._httpClient.get<PartnerCode>(`/api/partner-code/valid/${partnerCode}`).subscribe((response: PartnerCode) => {
        this.partnerCode = response;
        this.onPartnerCodeChanged.next(response);
        resolve(response);
      }, reject);
    });
  }

  savePartnerCode(partnerCode: PartnerCode): Promise<PartnerCode> {
    return new Promise((resolve, reject) => {
      this._httpClient.post<PartnerCode>(`/api/partner-code/`, partnerCode).subscribe((response: PartnerCode) => {
        this.partnerCode = response;
        this.onPartnerCodeChanged.next(response);
        resolve(response);
      }, reject);
    });
  }

  updatePartnerCode(partnerCode: PartnerCode): Promise<PartnerCode> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .put<PartnerCode>(`/api/partner-code/${partnerCode.id}`, partnerCode)
        .subscribe((response: PartnerCode) => {
          this.partnerCode = response;
          this.onPartnerCodeChanged.next(response);
          resolve(response);
        }, reject);
    });
  }

  deletePartnerCode(id: string): Promise<HttpResponse<PartnerCode>> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .delete<PartnerCode>(`/api/partner-code/${id}`, { observe: 'response' })
        .subscribe((response: HttpResponse<PartnerCode>) => {
          resolve(response);
        }, reject);
    });
  }

  updatePartnerCodeCreator(partnerCodeId: string, partnerId: string): Promise<PartnerCode> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .put<PartnerCode>(`/api/partner-code/${partnerCodeId}/${partnerId}`, null)
        .subscribe((response: PartnerCode) => {
          this.partnerCode = response;
          this.onPartnerCodeChanged.next(response);
          resolve(response);
        }, reject);
    });
  }
}
