import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { environment } from '@environments/environment';
import { ROUTES } from '@consts';
import { LayoutComponent } from '@shared/layout';
import { AuthGuard, AdminGuard } from './auth/helpers';
import { PublicLayoutComponent } from '@shared/public-layout/public-layout.component';

const appRoutes: Routes = [
  {
    path: ROUTES.NOT_FOUND,
    loadChildren: () => import('@pages/error/error.module').then((module) => module.ErrorModule),
  },
  {
    path: ROUTES.LOGIN,
    loadChildren: () => import('@pages/login/login.module').then((module) => module.LoginModule),
  },
  {
    path: ROUTES.REGISTER,
    loadChildren: () => import('@pages/register/register.module').then((module) => module.RegisterModule),
  },
  {
    path: ROUTES.RESET_PASSWORD,
    loadChildren: () =>
      import('@pages/reset-password/reset-password.module').then((module) => module.ResetPasswordModule),
  },
  {
    path: ROUTES.FORGOT_PASSWORD,
    loadChildren: () =>
      import('@pages/forgot-password/forgot-password.module').then((module) => module.ForgotPasswordModule),
  },
  {
    path: ROUTES.PROFILE,
    component: PublicLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('@pages/profile/profile.module').then((module) => module.ProfileModule),
      },
    ],
    canActivate: [AuthGuard],
  },
  {
    path: 'page',
    component: LayoutComponent,
    children: [
      {
        path: ROUTES.DASHBOARD,
        loadChildren: () => import('@pages/dashboard/dashboard.module').then((module) => module.DashboardModule),
      },
      {
        path: ROUTES.SETTINGS,
        loadChildren: () => import('@pages/dashboard/dashboard.module').then((module) => module.DashboardModule),
      },
      {
        path: ROUTES.PAYMENTS,
        loadChildren: () => import('@pages/payment/payment.module').then((module) => module.PaymentsModule),
      },
      {
        path: ROUTES.REFERRALS,
        loadChildren: () => import('@pages/referral/referral.module').then((module) => module.ReferralModule),
      },
      {
        path: ROUTES.PRE_PROFILE,
        loadChildren: () => import('@pages/profile/profile.module').then((module) => module.ProfileModule),
      },
      {
        path: ROUTES.EDIT_PROFILE,
        loadChildren: () =>
          import('@pages/account/edit/edit-profile.module').then((module) => module.EditProfileModule),
      },
      {
        path: ROUTES.VIEW_PROFILE,
        loadChildren: () =>
          import('@pages/account/view/view-profile.module').then((module) => module.ViewProfileModule),
      },
      {
        path: ROUTES.PARTNER_CODES,
        loadChildren: () =>
          import('@pages/partner-codes/partner-codes.module').then((module) => module.PartnerCodesModule),
      },
      {
        path: ROUTES.REVENUES,
        loadChildren: () =>
          import('@pages/revenue/revenue.module').then((module) => module.RevenuesModule),
      },
    ],
    canActivate: [AuthGuard],
  },
  {
    path: 'admin',
    component: LayoutComponent,
    children: [
      {
        path: ROUTES.ADMIN_PROFILES_IN_REVIEW,
        loadChildren: () =>
          import('@pages/admin/profiles-in-review/profiles-in-review.module').then(
            (module) => module.ProfilesInReviewModule,
          ),
      },
      {
        path: ROUTES.ADMIN_PROFILES,
        loadChildren: () => import('@pages/admin/profiles/profiles.module').then((module) => module.ProfilesModule),
      },
      {
        path: ROUTES.ADMIN_PROGRAMS,
        loadChildren: () => import('@pages/dashboard/dashboard.module').then((module) => module.DashboardModule),
      },
      {
        path: ROUTES.ADMIN_ADD_REFERRALS,
        loadChildren: () => import('@pages/admin/add-referrals/add-referrals.module').then((module) => module.AddReferralsModule),
      },
    ],
    canActivate: [AuthGuard, AdminGuard],
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: ROUTES.PAGE_DASHBOARD,
  },
  {
    path: '**',
    redirectTo: ROUTES.NOT_FOUND,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
      enableTracing: environment.debug.router,
      anchorScrolling: 'enabled',
      scrollPositionRestoration: 'enabled',
      relativeLinkResolution: 'legacy',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
