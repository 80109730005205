import { User } from "./user";

export interface Revenue {
    id: string;
    amount: number;
    holdAmountDate: Date;
    initialAmount: number;
    currency: string;
    stripeEventId: string;
    customer: string;
    partnerCodeId: string;
    metadata: Record<string, unknown>;
    user: User;
    createdAt: Date;
}