export interface BankDetails {
  id?: string;
  accountHolderName: string;
  accountHolderType?: string;
  bankName: string;
  country?: string;
  currency?: string;
  accountNumber: string;
  swift?: string;
  routing?: string;
}
