import { BehaviorSubject } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Payment } from "@types";

@Injectable()
export class PaymentService {
  public onPaymentsChanged: BehaviorSubject<Payment[]>;
  constructor(private _httpClient: HttpClient) {
    this.onPaymentsChanged = new BehaviorSubject([]);
  }

  getPaymentsOfUser(): Promise<Payment[]> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .get<Payment[]>(`/api/payment`)
        .subscribe((response: any) => {
          this.onPaymentsChanged.next(response);
          resolve(response);
        }, reject);
    });
  }
}
