import { User } from './user';

export enum Discount {
  ZERO = '0',
  FIVE = '5',
  TEEN = '10',
  FIFTEEN = '15',
  TWENTY = '20',
  HUNDRED = '100',
}

export enum Subscription {
  MONTHLY = 'monthly',
  ANNUAL = 'annual',
  ONETIME = 'onetime',
}

export interface PartnerCode {
  id: string;
  discount: Discount;
  partnerCode: string;
  startDate?: Date;
  endDate?: Date;
  isActive: boolean;
  isDeleted: boolean;
  subscriptionType: Subscription;
  isDisabledByAdmin: boolean;
  lastActiveState: boolean;
  revenueToDate: number;
  creator: User;
  createdAt: Date;
  updatedAt: Date;
  limit: number;
}
