<div class="navbar header-navbar navbar text-center navbar-shadow navbar-light">
  <a class="brand-logo w-100" href="javascript:void(0);">
    <img src="/assets/icons/icon-128x128.png" alt="Partner Portal Recovery logo" height="50" width="50" />
    <h2 class="brand-text text-primary">Partner Portal</h2></a
  >
</div>
<div class="public-content m-4">
  <main [@fadeInAnimation]="getRouterOutletState(outlet)" [@zoomIn]="zoomIn(outlet)" [@fadeInLeft]="fadeInLeft(outlet)" [@fadeIn]="fadeIn(outlet)">
    <router-outlet #outlet="outlet"></router-outlet>
  </main>
</div>
