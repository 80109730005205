import { Pipe, PipeTransform } from '@angular/core';
import { getFullName } from '@utils';

@Pipe({
  name: 'name'
})
export class NamePipe implements PipeTransform {
  transform(firstName: string, middleName: string, lastName: string): any {
    return getFullName(firstName, lastName, middleName);
  }
}
