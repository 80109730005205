import { BehaviorSubject } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Revenue } from "@types";

@Injectable()
export class RevenueService {
  public onRevenuesChanged: BehaviorSubject<Revenue[]>;
  constructor(private _httpClient: HttpClient) {
    this.onRevenuesChanged = new BehaviorSubject([]);
  }

  getRevenuesOfUser(): Promise<Revenue[]> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .get<Revenue[]>(`/api/revenue`)
        .subscribe((response: any) => {
          this.onRevenuesChanged.next(response);
          resolve(response);
        }, reject);
    });
  }
}
