export enum SocialProvider {
  GOOGLE = 'GOOGLE',
}

export interface Social {
  name: string;
  firstName: string;
  lastName: string;
  email: string;
  avatar: string;
  provider: SocialProvider;
  referralCode?: string;
}

export interface SocialEntity {
  firstName: string;
  lastName: string;
  email: string;
  avatar: string;
  id: string;
  isActive: boolean;
  connectedAt?: Date;
  provider: SocialProvider;
  username?: string;
  middleName?: string;
  createdAt: Date;
}
