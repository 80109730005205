import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { GoogleSignInModule } from '@/@core/components/google-sign-in/google-sign-in.module';
import { CoreCommonModule } from '@core/common.module';
import { CoreSidebarModule } from '@core/components';
import { TranslationService } from '@services';
import { LayoutComponent, FooterModule, NavbarModule, ContentHeaderModule, MenuModule } from './layout';
import { CustomBreakPointsProvider } from '@consts';
import { APIInterceptor, ErrorInterceptor, JwtInterceptor, RequestInterceptor } from '../auth/helpers';
import { PublicLayoutComponent } from './public-layout/public-layout.component';

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [LayoutComponent, PublicLayoutComponent],
  imports: [
    NgbModule,
    FormsModule,
    CommonModule,
    RouterModule,
    HttpClientModule,
    ReactiveFormsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      isolate: true,
    }),
    FlexLayoutModule.withConfig({ disableDefaultBps: true }),
    ToastrModule.forRoot(),
    FontAwesomeModule,
    CoreCommonModule,
    CoreSidebarModule,
    MenuModule,
    NavbarModule,
    FooterModule,
    ContentHeaderModule,
    GoogleSignInModule,
  ],
  providers: [
    TranslationService,
    CustomBreakPointsProvider,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: APIInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true },
  ],
  exports: [NgbModule, RouterModule, TranslateModule, CoreCommonModule, FontAwesomeModule, GoogleSignInModule],
})
export class SharedModule {
  constructor(private translation: TranslationService) {
    this.translation.init();
  }
}
