import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';

import { Roles } from '@types';
import { ROUTES } from '@consts';
import { AuthenticationService } from 'app/auth/service';

@Injectable({ providedIn: 'root' })
export class AdminGuard implements CanActivate {

  constructor(private _router: Router, private _authenticationService: AuthenticationService) {}

  canActivate() {
    const currentUser = this._authenticationService.currentUserValue;
    if (currentUser?.roles.includes(Roles.ADMIN)) {
      return true;
    }
    this._router.navigate([ROUTES.PAGE_DASHBOARD]);
    return false;
  }
}
