import { User } from "./user";

export interface Payment {
    id: string;
    withdrawnAmount: number;
    initialAmount: number;
    isBankTransfer: boolean;
    status: string;
    currency: string;
    transferId: string;
    payoutId: string;
    metadata: Record<string, unknown>;
    user: User;
    createdAt: Date;
}