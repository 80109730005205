import { HttpClient, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable()
export class PasswordService {
  constructor(private _httpClient: HttpClient) {}

  forgotPassword(email: string): Promise<void> {
    return new Promise((resolve, reject) =>
      this._httpClient
        .get<void>(`/api/password/forgot/${email}`)
        .subscribe(resolve, reject)
    );
  }

  resetPassword(password: {
    key: string;
    email: string;
    password: string;
  }): Promise<HttpResponse<void>> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .post<void>(`/api/password/reset`, password, {
          withCredentials: true,
          observe: "response",
        })
        .subscribe(resolve, reject);
    });
  }

  getEmailFromKey(key: string): Promise<string> {
    return new Promise((resolve, reject) =>
      this._httpClient
        .get(`/api/password/email/${key}`, { responseType: 'text' })
        .subscribe(resolve, reject)
    );
  }
}
