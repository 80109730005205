import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { User } from '@types';

@Injectable()
export class ReferralService {
  public referrals: User[];
  public onReferralsChanged: BehaviorSubject<User[]>;

  constructor(private _httpClient: HttpClient) {
    this.onReferralsChanged = new BehaviorSubject([]);
  }

  getReferrals(): Promise<User[]> {
    return new Promise((resolve, reject) => {
      this._httpClient.get<User[]>(`/api/user/referral`).subscribe((response: User[]) => {
        this.referrals = response;
        this.onReferralsChanged.next(response);
        resolve(response);
      }, reject);
    });
  }
}
