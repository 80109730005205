import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

import { deleteDataFromLocalStorage, getDataFromLocalStorage, setDataFromLocalStorage } from '@utils';
import { ACCESS_TOKEN_KEY, CURRENT_USER_KEY } from '@consts';
import { Roles, Social, User } from '@types';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  //public
  public currentUser: BehaviorSubject<User>;

  //private
  private tokenSubject: BehaviorSubject<string>;

  /**
   *
   * @param {HttpClient} _http
   * @param {ToastrService} _toastrService
   */
  constructor(private _http: HttpClient, private _toastrService: ToastrService) {
    this.currentUser = new BehaviorSubject<User>(getDataFromLocalStorage<User>(CURRENT_USER_KEY, null));
    this.tokenSubject = new BehaviorSubject<string>(getDataFromLocalStorage<string>(ACCESS_TOKEN_KEY, ''));
  }

  // getter: currentUserValue
  public get currentUserValue(): User {
    return this.currentUser.value;
  }

  public get accessToken(): string {
    return this.tokenSubject.value;
  }

  /**
   *  Confirms if user is admin
   */
  get isAdmin() {
    return this.currentUser.value?.roles.some((role) => role === Roles.ADMIN);
  }

  /**
   *  Confirms if user is client
   */
  get isClient() {
    return this.currentUser.value?.roles.some((role) => role === Roles.USER);
  }

  /**
   * User login
   *
   * @param email
   * @param password
   * @returns user
   */
  login(email: string, password: string) {
    return this._http.post<User>(`/api/auth/login`, { email, password }, { observe: 'response' }).pipe(
      map(({ body, headers }) => {
        if (body) {
          this.onLoginSuccess(headers, body);
        }

        return body;
      }),
    );
  }

  register(username: string, industry: string, email: string, password: string, referralCode?: string) {
    return this._http
      .post<User>(`/api/auth/register`, { username, industry, email, password, referralCode }, { observe: 'response' })
      .pipe(
        map(({ body, headers }) => {
          if (body) {
            this.onLoginSuccess(headers, body);
          }

          return body;
        }),
      );
  }

  registerWithSocial(socialUser: Social) {
    return this._http.post<User>(`/api/auth/register-social`, { ...socialUser }, { observe: 'response' }).pipe(
      map(({ body, headers }) => {
        if (body) {
          this.onLoginSuccess(headers, body);
        }

        return body;
      }),
    );
  }

  loginWithSocial(socialUser: Social) {
    return this._http.post<User>(`/api/auth/login-social`, { ...socialUser }, { observe: 'response' }).pipe(
      map(({ body, headers }) => {
        if (body) {
          this.onLoginSuccess(headers, body);
        }

        return body;
      }),
    );
  }

  private onLoginSuccess = (headers: HttpHeaders, user: User) => {
    const token = headers.get('Authorization').replace('Bearer ', '');
    setDataFromLocalStorage(CURRENT_USER_KEY, user);
    setDataFromLocalStorage(ACCESS_TOKEN_KEY, token);

    setTimeout(() => {
      this._toastrService.success(
        'You have successfully logged in ' + ' to Partner portal. Now you can start to explore. Enjoy! 🎉',
        '👋 Welcome, ' + user.firstName + ' ' + user.lastName + '!',
        { toastClass: 'toast ngx-toastr', closeButton: true },
      );
    }, 2500);
    this.currentUser.next(user);
    this.tokenSubject.next(token);
  };

  updateUser(user: User) {
    setDataFromLocalStorage(CURRENT_USER_KEY, user);
    this.currentUser.next(user);
  }

  /**
   * User logout
   *
   */
  logout() {
    // remove user from local storage to log user out
    deleteDataFromLocalStorage(CURRENT_USER_KEY);
    deleteDataFromLocalStorage(ACCESS_TOKEN_KEY);
    // notify
    this.tokenSubject.next(null);
    this.tokenSubject.complete();
  }
}
