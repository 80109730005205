import 'hammerjs';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { SocialLoginModule } from 'angularx-social-login';

import { SharedModule } from '@shared';
import { CoreModule } from '@core/core.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { coreConfig } from './app-config';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SocialLoginModule,
    CoreModule.forRoot(coreConfig),
    BrowserAnimationsModule,
    SharedModule,
    // ServiceWorkerModule.register('ngsw-worker.js', {
    //   enabled: true,
    //   registrationStrategy: 'registerImmediately',
    // }),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
