import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthenticationService } from 'app/auth/service';
import { ROUTES } from '@consts';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  /**
   * @param {Router} _router
   * @param {AuthenticationService} _authenticationService
   */
  constructor(private _router: Router, private _authenticationService: AuthenticationService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err) => {
        if ([401, 403].indexOf(err.status) !== -1) {
          // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
          this._router.navigate([ROUTES.LOGIN]);

          // ? Can also logout and reload if needed
          this._authenticationService.logout();
          // location.reload(true);
        }
        console.error(`An error occured while intercepting the request: ${request.url} with the error: `, {err});
        // throwError
        if (request.withCredentials) {
          return throwError(err);
        }
        const error = err.error.message || err.error.error || err.statusText;
        return throwError(error);
      }),
    );
  }
}
